import React, { useState } from 'react';
import firebase from "../../firebaseconfig";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import Input from '../../components/Input';
import Button from '../../components/Button';
import Header from '../../containers/Header';
import Loading from '../../components/Loading';

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router';

const NewPromoForm = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [urlBannerImage, setUrlBannerImage] = useState('');
  let file;

  const [questions, setQuestions] = useState([{ question: '', type: ''}]);
  const [isAddQuestion, setIsAddQuestion] = useState(false);
  
  let history = useHistory();
  const [showLoading, setShowLoading] = useState(false);

  const setData = (e) => {
    e.preventDefault();
    setShowLoading(true);
    const userId = firebase.auth().currentUser.uid;
    const userName = firebase.auth().currentUser.displayName;
    handleUploadBanner(e);

    setTimeout(() => {
      firebase
        .firestore()
        .collection("promoForms")
        .doc()
        .set({
          user: userId,
          userName: userName,
          banner: urlBannerImage,
          title: title,
          description: description,
          data: questions
        });
        redirectPage();
    }, 3000);
  }

  const redirectPage = () => {
    alert('form criado');
    history.push("/");
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...questions];
    list[index][name] = value;
    console.log(name);
    setQuestions(list);
    console.log(questions);
  };

  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...questions];
    list.splice(index, 1);
    setQuestions(list);
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    setQuestions([...questions, { question: '', type: ''}]);
  };

  const handleImage = (e) => {
    const { files } = e.target;
    if(files !== null) {
      file = files[0];
    }
  }

  const handleUploadBanner = (e) => {
    e.preventDefault();
    const storage = firebase.storage();
    const userId = firebase.auth().currentUser.uid;

    if(file !== undefined) {
      const uploadRef = storage.ref(`${userId}/banner/${file.name}`).put(file);
      uploadRef.on(
        "state_changed",
        snapshot => { },
        error => {
          console.log(error);
        },
        () => {
          storage
          .ref(`${userId}/banner`)
          .child(file.name)
          .getDownloadURL()
          .then(url => {
            setUrlBannerImage(url)
          })
        }
      )
    }
  }

  return (
  <>
    <Header />
    <Box mt={10}>
    { !showLoading
    ? <form>
        <Input 
          label="Imagem do banner"
          name="image"
          type="file"
          value={file}
          onChange={(e) => {handleImage(e); handleUploadBanner(e)}}
        />
        <Input 
          label="Nome do evento"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {/* <EditorComponent 
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        /> */}
        <textarea 
          label="Descrição do evento"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>

        { isAddQuestion
          ? <>
            {questions.map((item, index) => {
              return (
                <div key={index}>
                <fieldset>
                  <Input 
                    label="Pergunta"
                    name="question"
                    type="text"
                    value={item.question}
                    required
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  <select> Tipo
                    <option value="text">Texto</option>
                    <option value="number">Número</option>
                    <option value="email">E-mail</option>
                    <option value="date">Data</option>
                    <option value="time">Hora</option>
                  </select>
                  {questions.length !== 1 &&
                    <DeleteOutlineOutlinedIcon alt="Remover pergunta" onClick={(e) => handleRemoveClick(e, index)}/>  
                  }
                </fieldset>
              {questions.length - 1 === index &&
                <Button className={isAddQuestion ? "active" : "hidden"} text="Nova pergunta" icon='fas fa-plus' onClick={(e) => handleAddClick(e)}/>
              }
              </div>
              )
            })
            }
          </>
          : false
        }
        <Button className={!isAddQuestion ? "active" : "hidden"} text="Adicionar pergunta" onClick={(e) => {e.preventDefault(e);setIsAddQuestion(true)}}/>
        <Button text="Criar form" 
        onClick={(e) => setData(e)}/>
      </form>
    : <Loading />
    }
    </Box>
  </>
  )
}

export default NewPromoForm;