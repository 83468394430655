import React, { useState, useEffect } from 'react';
import firebase from "../../../firebaseconfig";
import "firebase/auth";
import "firebase/firestore";

import ListItemsContext from './';

const ListItemsProvider = (props) => {
  const [items, setItems] = useState([]);
  const [promoForms, setPromoForms] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        firebase
          .firestore()
          .collection("lists")
          .onSnapshot((snapshot) => {
            const listData = [];
            snapshot.forEach((doc) => listData.push({ ...doc.data(), id: doc.id }));
            setItems(listData);
        });
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        firebase
          .firestore()
          .collection("promoForms")
          .onSnapshot((snapshot) => {
            const listDataForm = [];
            snapshot.forEach((doc) => listDataForm.push({ ...doc.data(), id: doc.id }));
            setPromoForms(listDataForm);
        });
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  return (
    <ListItemsContext.Provider value={{ items: items, promoForms: promoForms }}>
      {props.children}
    </ListItemsContext.Provider>
  )
}

export default ListItemsProvider;