import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

import { ListItemsContext } from '../../containers/context';
import Header from '../../containers/Header';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PromoFormDetail from '../../containers/PromoFormDetail';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 60,
    color: '#424242'
  },
}));

const PromoFormSelected = () => {
  const classes = useStyles();

  const { promoForms } = useContext(ListItemsContext);
  const [promoItemDetail, setPromoItemDetail] = useState([]);
  let location = useLocation();
  
  useEffect(() => {
    let pathname = location.pathname;
    let listCode = pathname.split("/")[2];
    const itemList = promoForms.filter((item) => item.id.includes(listCode));

    setPromoItemDetail(itemList)
  }, [promoForms, location.pathname]);
  
  return (
    <>
      <Header />
      <Typography variant="body2" component="main" className={classes.root}>
        <PromoFormDetail promoItemDetail={promoItemDetail}/>
      </Typography>
    </>
    )
  }
  
  export default PromoFormSelected;
  