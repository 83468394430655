import React, { useState, useEffect, useContext } from 'react';
import firebase from "../../firebaseconfig";
import "firebase/auth";
import "firebase/firestore";

import UserListItem from '../../components/UserListItem';
import Loading from '../../components/Loading';
import Header from '../../containers/Header';

import { ListItemsContext } from '../../containers/context';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  list: {
    margin: 0,
    padding: 0,
  },
});

const UserLists = () => {
  const classes = useStyles();

  const { items } = useContext(ListItemsContext);
  const [userLogged, setUserLogged] = useState(null);
  const [userLists, setUserLists] = useState([]);
  let history = useHistory();
  
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setUserLogged(user.uid);
      const listItem = [];
      items.forEach((list) => {
        if(userLogged === list.user) {
          listItem.push(list)
        }
      });
      setTimeout(() => {
        setUserLists(listItem);
      }, 1000);
    });
  }, [items, userLogged])

  const handleListDetail = (listCode) => {
    history.push('/list/' + listCode);
  }

  const handleRemoveList = (id) => {
    firebase
      .firestore()
      .collection("lists")
      .onSnapshot((snapshot) => {
        snapshot.forEach(doc => {
          if(doc.id === id) {
            doc.ref.delete();
          }
        });
    });
  }

  return (
    <>
      <Header />
      <Box mt={10}>
        { userLists.length === 0 || userLists === undefined
          ? <Loading />
          : <>
              <ul className={classes.list}>
                {userLists.map((item) => <UserListItem key={item.id} listItem={item} onClick={() => handleListDetail(item.id)} onDeleteClick={() => handleRemoveList(item.id)}/>)}
              </ul>
            </>
        }
      </Box>
    </>
  );
}

export default UserLists;