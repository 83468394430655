import React from 'react';
import Btn from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const Button = ({ id, text, icon, ...props }) => {
  return (
    // <button 
    //   id={id}
    //   {...props}
    // >
    //   {text}
    //   <i className={icon}></i>
    // </button>
    <Box my={2}>
      <Btn id={id} {...props}>
        {text}
      </Btn>
    </Box>
  )
}

export default Button;