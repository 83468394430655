import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ListItemsProvider } from '../context';
import Routes from '../../routes'
import Footer from '../Footer'

import '../../styles/main.scss';
import Container from '@material-ui/core/Container';
import { ThemeProvider } from '@material-ui/core';
import {dark} from '../../styles/theme';

const App = () => (
  <Container maxWidth="xs">
    <ThemeProvider theme={dark}>
      <ListItemsProvider>
        <BrowserRouter>
          <Routes/>
          <Footer />
        </BrowserRouter>
      </ListItemsProvider>
    </ThemeProvider>
  </Container>
)
  
export default App;