import React, { useState } from 'react';

// import SearchList from '../../containers/SearchList';
import FormLogin from '../../containers/FormLogin';
import FormRegister from '../../containers/FormRegister';

import Button from '../../components/Button';
import Container from '@material-ui/core/Container';
import { ButtonGroup, AppBar, Tabs, Tab } from '@material-ui/core';
import TabContent from '../../components/TabContent';

// const Dashboard = () => {
//   const [content, setContent] = useState(<FormLogin />);
//   // const [content, setContent] = useState(<SearchList />);

//   const handleOpt = (e, showItem) => {
//     e.preventDefault();
//     switch(showItem){
//       case 'login':
//         return setContent(<FormLogin />);
//       case 'register':
//         return setContent(<FormRegister />);
//       default:
//         return setContent(<FormLogin />);
//         // return setContent(<SearchList />);
//     }
//   }

//     return (
//       <>
//         {/* <Button 
//           id="btn-show-search"
//           type="submit"
//           text="Busca lista"
//           onClick={(e) => handleOpt(e, 'search')}
//         /> */}
//         <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
//           <Button 
//             id="btn-show-login"
//             type="submit"
//             text="Login"
//             onClick={(e) => handleOpt(e, 'login')}
//           />
//           <Button 
//             id="btn-show-register"
//             type="submit"
//             text="Cadastro"
//             onClick={(e) => handleOpt(e, 'register')}
//           />
//         </ButtonGroup>
//         <AppBar position="static">
//           <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
//             <Tab label="Login" {...a11yProps(0)} />
//             <Tab label="Cadastro" {...a11yProps(1)} />
//           </Tabs>
//         </AppBar>
//         <TabPanel value={value} index={0}>
//           <FormLogin />
//         </TabPanel>
//         <TabPanel value={value} index={1}>
//           <FormRegister />
//         </TabPanel>
//         {content}
//         </>
//       );
//     }
    
//     export default Dashboard;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Dashboard = () => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs centered value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Login" {...a11yProps(0)} />
        <Tab label="Cadastro" {...a11yProps(1)} />
      </Tabs>
      <TabContent value={value} index={0}>
        <FormLogin/>
      </TabContent>
      <TabContent value={value} index={1}>
        <FormRegister/>
      </TabContent>
    </>
  );
}

export default Dashboard;