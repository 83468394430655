import React from 'react';

const Image = ({ src, alt, loading, className }) => {
  return (
    <figure className={className}>
      <img 
        src={src} 
        alt={alt} 
         />
    </figure>
  )
}

export default Image;