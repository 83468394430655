import React from 'react';

import Header from '../../containers/Header';
import { Typography, Box } from '@material-ui/core';


const Home = () => {

  return (
    <>
      <Header />
      <Box mt={10}>
        <Typography variant="body2">
          logado
        </Typography>
      </Box>
    </>
  );
}

export default Home;