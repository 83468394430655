import React from 'react';
import { useHistory, NavLink } from "react-router-dom";
import firebase from "../../firebaseconfig";
import "firebase/auth";
import "firebase/firestore";

import Button from '../../components/Button';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, makeStyles } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Header = () => {
  let history = useHistory();

  const logout = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push("/");
      })
      .catch(console.error);
  };

  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorElM, setAnchorElM] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openM = Boolean(anchorElM);
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenuM = (event) => {
    setAnchorElM(event.currentTarget);
  };

  const handleCloseM = () => {
    setAnchorElM(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  

  return (
   <>
    <AppBar position="fixed">
        <Toolbar>
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon
            aria-label="actions"
            aria-controls="menu"
            aria-haspopup="true"
            onClick={handleMenuM}
            color="inherit" />
            <Menu
                id="menu"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={openM}
                onClose={handleCloseM}
              >
                <MenuItem>
                  <NavLink to="/my-lists">Minhas listas</NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to="/new-list">Criar nova lista </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to="/my-promo-forms">Meus formulários</NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to="/new-promo-form">Criar novo formulário</NavLink>
                </MenuItem>
                <MenuItem>
                  <a href="/" onClick={(e) => logout(e)}>Sair</a>
                </MenuItem>
              </Menu>
          </IconButton> */}
          <Typography variant="h6" className={classes.title}>
            Chá da Margot
          </Typography>
          {/* {auth && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-profile"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-profile"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Perfil</MenuItem>
                <MenuItem onClick={logout}>Sair</MenuItem>
              </Menu>
            </div>
          )} */}
        </Toolbar>
      </AppBar>
   </>
  )
}

export default Header;
