import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

import ListDetail from '../../containers/ListDetail';
import { ListItemsContext } from '../../containers/context';
import Header from '../../containers/Header';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 60,
    color: '#424242'
  },
}));

const ListSelected = () => {
  const classes = useStyles();

  const { items } = useContext(ListItemsContext);
  const [listItemDetail, setListItemDetail] = useState([]);
  let location = useLocation();
  
  useEffect(() => {
    let pathname = location.pathname;
    let listCode = pathname.split("/")[2];
    const itemList = items.filter((item) => item.id.includes(listCode));

    setListItemDetail(itemList)
  }, [items, location.pathname]);
  
  return (
    <>
      <Header />
      <Typography variant="body2" component="main" className={classes.root}>
        <ListDetail listItemDetail={listItemDetail}/>
      </Typography>
    </>
    )
  }
  
  export default ListSelected;
  