import React from 'react'

const Loading = () => (
  <div class="content">
    <div class="planet">
        <div class="ring"></div>
          <div class="cover-ring"></div>
        <div class="spots">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>

        </div>
    </div>
    <p>loading</p>
  </div>
);

export default Loading;
