import React, { useState, useEffect } from 'react';
import firebase from "../../firebaseconfig";
import "firebase/auth";
import "firebase/firestore";

import Button from '../../components/Button';
import Input from '../../components/Input';
import Header from '../Header';
import Image from '../../components/Image/Image';

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { Box, Fab, makeStyles, AppBar, Toolbar, List, Divider, ListItem, ListItemAvatar, ListItemText, Dialog, Container, Snackbar } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CheckIcon from '@material-ui/icons/Check';
import YouTubeIcon from '@material-ui/icons/YouTube';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  appBarModal: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'transparent',
    boxShadow: "none"
  },
  grow: {
    flexGrow: 1,
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1,
    top: -26,
    left: 0,
    right: 180,
    margin: '0 auto',
  },
  addButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  saveButton: {
    position: 'absolute',
    zIndex: 1,
    top: -26,
    left: 0,
    right: -180,
    margin: '0 auto',
  },
  banner: {
    marginLeft: 0,
    marginRight: 0,
    '& img': {
      width: '100%',
      display: 'block',
    },
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    '& a': {
      textDecoration: 'none'
    }
  },
  listImage: {
    marginLeft: 0,
    marginRight: '15px',
    '& img': {
      width: '100px',
      display: 'block'
    }
  },
  listDivider: {
    marginLeft: 0,
    marginRight: 0,
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '10px',
    textDecoration: 'none',
    '& :hover': {
      textDecoration: 'underline',
    },
    '& :visited': {
      textDecoration: 'none'
    }
  },
  buttonGroup: {
    display: 'flex',
    '& >div': {
      marginRight: 20,
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PromoFormDetail = ({ promoItemDetail }) => {
  const classes = useStyles();

  const [promoDetail, setPromoDetail] = useState({});
  const [userLogged, setUserLogged] = useState(null);

  const [isInputActive, setIsInputActive] = useState(false);
  const [isInputBannerActive, setIsInputBannerActive] = useState(false);
  const [isInputProductActive, setIsInputProductActive] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);

  const [titleValue, setTitleValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [data, setData] = useState([]);

  const [urlBannerImage, setUrlBannerImage] = useState('');
  let file;
  const [stateSnack, setStateSnack] = useState({
    openSnack: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, openSnack } = stateSnack;
  const handleSnackClickOpen = (newStateSnack) => {
    setStateSnack({ openSnack: true, ...newStateSnack });
  };

  const handleSnackClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setStateSnack({ ...stateSnack, openSnack: false });
  };

  useEffect(() => {
    let promoDetail = promoItemDetail[0];
    setPromoDetail(promoDetail);
  }, [promoItemDetail]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      user
        ? setUserLogged(user.uid)
        : setUserLogged(null)
    });
  }, []);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...data];
    list[index][name] = value;
    setData(list);
  };

  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...data];
    list.splice(index, 1);
    setData(list);
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    setData([...data, { question: '', type: ''}]);
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    if(promoDetail.items <= 0) {
      setData([{ question: '', type: ''}]);
    } else {
      setData(promoDetail.data)
    }
  }

  const handleUpdateList = (e, id) => {
    e.preventDefault();
    firebase
      .firestore()
      .collection("promoForms")
      .doc(id)
      .update({
        // banner: urlBannerImage === '' ? promoDetail.banner : urlBannerImage,
        title: titleValue === '' ? promoDetail.title : titleValue,
        description: descriptionValue === '' ? promoDetail.description : descriptionValue,
        data: promoDetail.data === [] ? promoDetail.data : data
      });
  }

  // useEffect(() => {
  //   return upToBuyValue && selectedIndex && productName && productQuantity && items && openSnack && buyerValue;
  // }, [selectedIndex, upToBuyValue, items, productName, productQuantity, openSnack, buyerValue])
  
  return (
  <>
    {promoDetail !== undefined &&
    <>
      <Image src={promoDetail.banner} className={classes.banner}/>
      <form class={isInputBannerActive ? "active" : "hidden"}>
        {/* <Input label="Imagem do banner" name="image" type="file" value={file} onChange={(e) => {handleImage(e); handleChangeBanner(e)}}/> */}
      </form>
      
      {userLogged &&
      <>
        <Box display={!isInputBannerActive ? "block" : "none"}>
          <Button className={classes.button} text="Editar banner" onClick={() => setIsInputBannerActive(true)} />
        </Box>
        <Box display={isInputBannerActive ? "block" : "none"}>
          <Button className={classes.button} text="Cancelar edição do banner" onClick={() => setIsInputBannerActive(false)}/>
          {/* <Button className={classes.button} text="Upload" onClick={(e) => {handleUploadBanner(e, promoDetail.id); setIsInputBannerActive(false)}}/> */}
        </Box>
      </>
      }

      <section class={!isInputActive ? "active" : "hidden"}>
        {/* <h3>Lista de {promoDetail.userName}</h3> */}
        <h2>{promoDetail.title}</h2>
        <article dangerouslySetInnerHTML={{ __html: promoDetail.description }}></article>
        {/* <Box>
          <h3>Endereço do evento</h3>
          <p>{promoDetail.address}</p>
        </Box> */}
      </section>
      <form class={isInputActive ? "active" : "hidden"}>
        <Input label="Título do evento" type="text" value={titleValue} onChange={(e) => setTitleValue(e.target.value)}/>
        <textarea label="Descrição do evento" type="text" value={descriptionValue} onChange={(e) => setDescriptionValue(e.target.value)}></textarea>
      </form>

      {userLogged &&
      <>
        <Box display={!isInputActive ? "block" : "none"}>
          <Button className={classes.button} 
            text="Editar descrição" 
            onClick={() => {
              setIsInputActive(true); 
              setTitleValue(promoDetail.title);
              setDescriptionValue(promoDetail.description);
            }}
          />
        </Box>
        <Box display={isInputActive ? "block" : "none"}>
          <Button className={classes.button} text="Cancelar edição da descrição" onClick={() => setIsInputActive(false)}/>
          <Button className={classes.button} text="Salvar" onClick={(e) => {handleUpdateList(e, promoDetail.id); setIsInputActive(false); setIsAddItem(false)}} />
        </Box>
      </>
      }

      { promoDetail.data !== undefined &&
      <>
        <List display={!isInputProductActive ? "block" : "none"}>
        {promoDetail.data.map((item, index) => {
          return (
          <>
            <Input label={item.question} type={item.type}/>
            <Divider  className={classes.listDivider}  variant="middle" />
          </>
          )}
        )}
        </List>
        {isInputProductActive &&
        <>
          {data.map((item, index) => {
            return (
            <div key={index}>
              <fieldset>
              <Input 
                    label="Pergunta"
                    name="question"
                    type="text"
                    value={item.question}
                    required
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  <select> Tipo
                    <option name="type" value="text">Texto</option>
                    <option name="type" value="number">Número</option>
                    <option name="type" value="email">E-mail</option>
                    <option name="type" value="date">Data</option>
                    <option name="type" value="time">Hora</option>
                  </select>
                {data.length !== 0 &&
                  <DeleteOutlineOutlinedIcon alt="Remover item da lista" onClick={(e) => handleRemoveClick(e, index)}/>  
                } 
              </fieldset>
              {data.length - 1 === index &&
                  <AppBar position="fixed" color="primary" display={!isAddItem ? "block" : "none"} className={classes.appBar}>
                    <Toolbar>
                      {isInputProductActive &&
                      <Fab size="medium" color="secondary" aria-label="Cancelar edição" className={classes.closeButton} onClick={() => setIsInputProductActive(false)}>
                        <CloseIcon />
                      </Fab>
                      }
                      <Fab color="primary" aria-label="Novo item" className={classes.addButton} onClick={(e) => handleAddClick(e)}>
                        <AddIcon />
                      </Fab>
                      {userLogged &&
                      <Fab size="medium" color="secondary" aria-label="Salvar itens" className={classes.saveButton} onClick={(e) => {handleUpdateList(e, promoDetail.id); setIsInputActive(false); setIsAddItem(false)}}>
                        <SaveIcon />
                      </Fab>
                    } 
                    </Toolbar>
                  </AppBar>
                }
            </div>
            )
          })
          }
        </>
        }

        {userLogged &&
        <>
          <Box display={!isInputProductActive ? "block" : "none"}>
            <Button className={classes.button} text={promoDetail.data.length <= 0 ? "Adicionar itens" : "Editar itens"} onClick={(e) => {setIsInputProductActive(true); handleEditClick(e)}}/>
          </Box>
        </>
        }
      </>
      } 
    
    </>
    }
  </>
  );
}

export default PromoFormDetail;