import React, { useState } from 'react';
import firebase from "../../firebaseconfig";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import Input from '../../components/Input';
import Button from '../../components/Button';
import Header from '../../containers/Header';
import Loading from '../../components/Loading';

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router';

const NewListForm = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [address, setAddress] = useState('');
  const [arriveAddress, setArriveAddress] = useState('');
  const [callLink, setCallLink] = useState('');

  const [urlBannerImage, setUrlBannerImage] = useState('');
  let file;

  const [items, setItems] = useState([{ product: '', brand: '', quantity: 0, link: '', image: '', category: '', buyer: [], totalBuy: 0, upToBuy: 0 }]);
  const [isAddItem, setIsAddItem] = useState(false);
  
  let history = useHistory();
  const [showLoading, setShowLoading] = useState(false);

  const setData = (e) => {
    e.preventDefault();
    setShowLoading(true);
    const userId = firebase.auth().currentUser.uid;
    const userName = firebase.auth().currentUser.displayName;
    handleUploadBanner(e);
    handleUploadProducts(e);

    setTimeout(() => {
      firebase
        .firestore()
        .collection("lists")
        .doc()
        .set({
          user: userId,
          userName: userName,
          banner: urlBannerImage,
          title: title,
          description: description,
          date: date,
          time: time,
          address: address,
          arriveAddress: arriveAddress,
          callLink: callLink,
          items: items
        });
        redirectPage();
    }, 10000);
  }

  const redirectPage = () => {
    alert('lista criada');
    history.push("/");
  }

  const handleInputChange = (e, index) => {
    const { name, value, files } = e.target;
    const list = [...items];
    if(files !== null) {
      list[index] = {...list[index], url: files[0]}
    }
    list[index][name] = value;
    list.map(item => {
      return item.upToBuy = item.quantity
    })
    setItems(list);
  };

  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...items];
    list.splice(index, 1);
    setItems(list);
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    setItems([...items, { product: '', brand: '', quantity: 0, link: '', image: '', category: '', buyer: [], totalBuy: 0, upToBuy: 0 }]);
  };

  const handleImage = (e) => {
    const { files } = e.target;
    if(files !== null) {
      file = files[0];
    }
  }

  const handleUploadBanner = (e) => {
    e.preventDefault();
    const storage = firebase.storage();
    const userId = firebase.auth().currentUser.uid;

    if(file !== undefined) {
      const uploadRef = storage.ref(`${userId}/banner/${file.name}`).put(file);
      uploadRef.on(
        "state_changed",
        snapshot => { },
        error => {
          console.log(error);
        },
        () => {
          storage
          .ref(`${userId}/banner`)
          .child(file.name)
          .getDownloadURL()
          .then(url => {
            setUrlBannerImage(url)
          })
        }
      )
    }
  }

  const handleUploadProducts = (e) => {
    e.preventDefault();
    const storage = firebase.storage();
    const userId = firebase.auth().currentUser.uid;

    const list = [...items];
    list.forEach(item => {
      if(item.url !== undefined) {
        const uploadRef = storage.ref(`${userId}/product/${item.url.name}`).put(item.url);
        uploadRef.on(
          "state_changed",
          snapshot => { },
          error => {
            console.log(error);
          },
          () => {
            if(item.url.name !== undefined) {
              storage
              .ref(`${userId}/product`)
              .child(item.url.name)
              .getDownloadURL()
              .then(url => {
                item.url = url
              })
            }
          }
        )
      }
    })
  }

  return (
  <>
    <Header />
    <Box mt={10}>
    { !showLoading
    ? <form>
        <Input 
          label="Imagem do banner"
          name="image"
          type="file"
          value={file}
          onChange={(e) => {handleImage(e); handleUploadBanner(e)}}
        />
        <Input 
          label="Nome do evento"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {/* <EditorComponent 
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        /> */}
        <textarea 
          label="Descrição do evento"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
        <Input 
          label="Data do evento"
          type="date"
          // defaultValue="aaaa-mm-dd"
          value={date !== '' ? date : "2000-01-01"}
          onChange={(e) => setDate(e.target.value)}
        />
        <Input 
          label="Hora do evento"
          type="time"
          value={time !== '' ? time : "00:00"}
          onChange={(e) => setTime(e.target.value)}
        />
        <Input 
          label="Local do evento"
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <Input 
          label="Endereço de entrega"
          type="text"
          value={arriveAddress}
          onChange={(e) => setArriveAddress(e.target.value)}
        />
        <Input 
          label="Link do evento"
          type="text"
          value={callLink}
          onChange={(e) => setCallLink(e.target.value)}
        />
        { isAddItem
          ? <>
            {items.map((item, index) => {
              return (
                <div key={index}>
                <fieldset>
                <Input 
                  label="Imagem do produto"
                  name="image"
                  type="file"
                  accept="image/jpeg"
                  onChange={(e) => {handleInputChange(e, index); handleUploadProducts(e)}}
                />
                  <Input 
                    label="Produto"
                    name="product"
                    type="text"
                    value={item.product}
                    required
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  <Input 
                    label="Marca"
                    name="brand"
                    type="text"
                    value={item.brand}
                    required
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  <Input 
                    label="Quantidade"
                    name="quantity"
                    type="number"
                    value={item.quantity}
                    required
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  <Input 
                    label="Link"
                    name="link"
                    type="url"
                    value={item.link}
                    placeholder="https://example.com"
                    pattern="https://.*"
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  <Input 
                    label="Categoria"
                    name="category"
                    type="text"
                    value={item.category}
                    required
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  {items.length !== 1 &&
                    <DeleteOutlineOutlinedIcon alt="Remover item da lista" onClick={(e) => handleRemoveClick(e, index)}/>  
                  }
                </fieldset>
              {items.length - 1 === index &&
                <Button className={isAddItem ? "active" : "hidden"} text="Novo item" icon='fas fa-plus' onClick={(e) => handleAddClick(e)}/>
              }
              </div>
              )
            })
            }
          </>
          : false
        }
        <Button className={!isAddItem ? "active" : "hidden"} text="Adicionar itens" onClick={(e) => {e.preventDefault(e); setIsAddItem(true)}}/>
        <Button text="Criar lista" 
        onClick={(e) => setData(e)}/>
      </form>
    : <Loading />
    }
    </Box>
  </>
  )
}

export default NewListForm;