import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../firebaseconfig";
import "firebase/auth";
import "firebase/firestore";

import Button from '../../components/Button';
import Input from '../../components/Input';

import {brands} from '../../styles/theme';
import { ThemeProvider } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';

const FormLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let history = useHistory();

  const loginEmail = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        firebase
          .firestore()
          .collection("user")
          .doc(user.user.uid)
          .get()
          .then((res) => {
            console.log(res.data());
            history.push("/");
          });
      });
  }

  const loginProvider = (provider) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        console.log(result);
        const userId = firebase.auth().currentUser.uid;
        firebase
          .firestore()
          .collection("user")
          .doc(userId)
          .set({
            id: result.user.uid,
            name: result.user.displayName,
            email: result.user.email,
        })
        .then(
          firebase
          .auth()
          .currentUser.updateProfile({
            displayName: result.user.displayName,
          })
        );
      });
  }
    
  const authFacebook = (e) =>{
    e.preventDefault();
    let provider = new firebase.auth.FacebookAuthProvider();
    loginProvider(provider);
    console.log('vc está logado pelo facebook');
  }
      
  const authGoogle = (e) =>{
    e.preventDefault();
    let provider = new firebase.auth.GoogleAuthProvider();
    loginProvider(provider);
    console.log('vc está logado pelo gmail');
  }

  return (
    <>
    <form>
      <Input
        label="E-mail"
        id="email-login"
        type="email"
        name="email-login"
        placeholder="seuemail@dominio.com.br"
        required
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        label="Senha"
        id="password-login"
        type="password"
        name="password-login"
        placeholder="Mínimo de 6 dígitos"
        required
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        id="btn-login"
        type="submit"
        text="Entrar"
        fullWidth
        variant="contained"
        color="primary"
        onClick={(e) => loginEmail(e)}
      />
    </form>
    <ThemeProvider theme={brands}>
      <Button
        id="btn-google"
        type="submit"
        text="Entrar com Google"
        fullWidth
        variant="contained"
        color="secondary"
        onClick={(e) => authGoogle(e)}
      />
      <Button
        id="btn-facebook"
        type="submit"
        text="Entrar com Facebook"
        fullWidth
        variant="contained"
        color="primary"
        startIcon={<FacebookIcon />}
        onClick={(e) => authFacebook(e)}
      />
    </ThemeProvider>
    </>
  )
}

export default FormLogin;