import React from 'react';

import Header from '../../containers/Header';

const LiveLink = () => {

  return (
    <>
      <Header />
      {window.location.href = "https://www.youtube.com/watch?v=OUZpG8rosao"}
    </>
  )
}

export default LiveLink;