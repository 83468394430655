import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import firebase from "../firebaseconfig";
import "firebase/auth";
import "firebase/firestore";

import Dashboard from './Dashboard';
import Home from './Home';
import ListSelected from './ListSelected';
import UserLists from './UserLists';
import NewListForm from './NewListForm';
import NewPromoForm from './NewPromoForm';
import UserPromoForms from './UserPromoForms';
import PromoFormSelected from './PromoFormSelected';
import PromoEmbed from './PromoEmbed';
import LiveLink from './LiveLink';

const Routes = () => {
  const [loggedIn, setLoggedIn] = useState(null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      user
        ? setLoggedIn(user.uid)
        : setLoggedIn(null)
    });
  }, []);

  return (
    <>
    <Switch>
      <Route exact path="/">
        {loggedIn ? <Home/> : <Dashboard />}
      </Route>
      <Route path="/new-list">
        <NewListForm />
      </Route>
      <Route path="/my-lists">
        <UserLists />
      </Route>
      <Route path="/list/:listCode">
        <ListSelected />
      </Route>
      <Route path="/new-promo-form">
        <NewPromoForm />
      </Route>
      <Route path="/my-promo-forms">
        <UserPromoForms />
      </Route>
      <Route path="/promo-form/:listCode">
        <PromoFormSelected />
      </Route>
      <Route path="/bolao">
        <PromoEmbed />
      </Route>
      <Route path="/live">
        <LiveLink />
      </Route>
      {/* <Route path="/search-lists/:listCode">
        <ListDetail />
      </Route> */}
    </Switch>
    </>
  )
}

export default Routes;