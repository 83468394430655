import React, { useState } from "react";
import firebase from "../../firebaseconfig";
import "firebase/auth";
import "firebase/firestore";

import Button from '../../components/Button';
import Input from '../../components/Input';

import {brands} from '../../styles/theme';
import { ThemeProvider } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';

const FormRegister = () => {
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');

  const register = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .createUserWithEmailAndPassword(mail, password)
      .then(() => {
        const userId = firebase.auth().currentUser.uid;
        firebase
          .firestore()
          .collection("user")
          .doc(userId)
          .set({
            id: userId,
            name: name,
            email: mail,
          })
          .then(
            firebase
            .auth()
            .currentUser.updateProfile({
              displayName: name,
            })
            );
          subCollection();
      });
    console.log('vc se cadastrou');
  }

  const subCollection = () => {
    const userId = firebase.auth().currentUser.uid;
    firebase
      .firestore()
      .collection("user")
      .doc(userId)
      .collection("lists")
      .doc()
  }

  const loginProvider = (provider) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        console.log(result);
        const userId = firebase.auth().currentUser.uid;
        firebase
          .firestore()
          .collection("user")
          .doc(userId)
          .set({
            id: result.user.uid,
            name: result.user.displayName,
            email: result.user.email,
        })
        .then(
          firebase
          .auth()
          .currentUser.updateProfile({
            displayName: result.user.displayName,
          })
        );
      });
  }
    
  const authFacebook = (e) =>{
    e.preventDefault();
    let provider = new firebase.auth.FacebookAuthProvider();
    loginProvider(provider);
    console.log('vc está logado pelo facebook');
  }
      
  const authGoogle = (e) =>{
    e.preventDefault();
    let provider = new firebase.auth.GoogleAuthProvider();
    loginProvider(provider);
    console.log('vc está logado pelo gmail');
  }

  return (
    <>
    <form action="submit">
      <Input
        label="Nome"
        id="name-register"
        type="text"
        name="name-register"
        placeholder="Seu nome completo"
        required
        fullWidth
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        label="E-mail"
        id="mail-register"
        type="email"
        name="mail-register"
        placeholder="seuemail@dominio.com.br"
        required
        fullWidth
        onChange={(e) => setMail(e.target.value)}
      />
      <Input
        label="Senha"
        id="password-register"
        type="password"
        name="password-register"
        placeholder="Mínimo de 6 dígitos"
        required
        fullWidth
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        id="btn-register"
        type="submit"
        text="Cadastrar"
        variant="contained"
        color="primary"
        fullWidth
        mt={1}
        onClick={(e) => register(e)}
      />
    
    </form>
    <ThemeProvider theme={brands}>
      <Button
        id="btn-google"
        type="submit"
        text="Cadastrar com Google"
        variant="contained"
        color="secondary"
        fullWidth
        href=""
        onClick={(e) => authGoogle(e)}
      />
      <Button
        id="btn-facebook"
        type="submit"
        text="Cadastrar com Facebook"
        variant="contained"
        color="primary"
        href=""
        startIcon={<FacebookIcon />}
        fullWidth
        onClick={(e) => authFacebook(e)}
        />
    </ThemeProvider>
    </>
  )
}

export default FormRegister;