import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 140,
  },
  list: {
    listStyle: 'none',
  },
  margin: {
    marginTop: 20,
    marginBottom: 5,
  }
});

const UserListItem = ({ listItem, onClick, onDeleteClick }) => {

  const classes = useStyles();

  return (
    <>
    {/* // <li>
    //   <p onClick={onClick}>{listItem.title}</p>
    //   <DeleteOutlineOutlinedIcon alt="Remover item da lista" onClick={onDeleteClick}/>  
    // </li> */}

    <li className={`${classes.list} ${classes.margin}`}>
      <Card className={classes.root}>
        <CardActionArea onClick={onClick}>
          <CardMedia
            className={classes.media}
            image={listItem.banner}
            title="Contemplative Image"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
            {listItem.title}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary">
            <DeleteOutlineOutlinedIcon alt="Remover item da lista" onClick={onDeleteClick}/>
          </Button>
        </CardActions>
      </Card>
    </li>
    </>
  )
}

export default UserListItem;
