import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC2GisdWIVO8Wq4lOYJVvmZTKGGK1gjjkA",
  authDomain: "gift-list-masma.firebaseapp.com",
  projectId: "gift-list-masma",
  storageBucket: "gift-list-masma.appspot.com",
  messagingSenderId: "1067422343660",
  appId: "1:1067422343660:web:2b2045b37c9e91233aa0c9"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };