import React from 'react';
import TextField from '@material-ui/core/TextField';

const Input = ({ id, label, onChange, ...props}) => {
  return (
    <TextField 
      id={id} 
      label={label} 
      variant="outlined"
      margin="normal"
      fullWidth
      {...props}
      InputProps={
        onChange={onChange} 
      } />
  ) 
}

export default Input;